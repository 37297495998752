<template>
  <div class="pre-onboarding">
    <template v-if="!completing">
      <div class="pre-steps">
        <div class="pre-progress">
          <div class="pre-progress--bar pre-progress--bar-active"></div>
          <div class="pre-progress--bar pre-progress--bar-active"></div>
          <div class="pre-progress--bar pre-progress--bar-active"></div>
          <div
            class="pre-progress--bar"
            :class="{ 'pre-progress--bar-active': isLastStep }"
          ></div>
        </div>
        <div v-if="!isLastStep">
          <div class="pre-step">
            <div class="pre-subhead centered">You are {{ prevWord }}</div>

            <div class="pre-headline centered glowing">{{ jobTitle }}</div>

            <div class="pre-pad"></div>

            <div class="pre-divider"></div>

            <div class="pre-pad"></div>

            <div class="pre-copy">
              <p>
                A communications expert with other worldly entities. Your
                knowledge and experience in everything supernatural makes you a
                valuable member to our team.
              </p>
              <p v-if="onboardingData.isAdult">
                What alias would you like to use for identification?
              </p>
              <p v-if="!onboardingData.isAdult">
                Your alias for Identification is:
              </p>
            </div>

            <div class="pre-pad"></div>

            <div class="pre-subhead glowing">
              <div class="pre-subhead-action" v-if="onboardingData.isAdult">
                <pnw-input
                  autofocus
                  class="pre-subhead-action--copy"
                  v-model="nickName"
                />
                <img
                  class="pre-subhead-action--icon"
                  src="/assets/images/icons/pencil.svg"
                  alt=""
                  @click="editing = true"
                />
              </div>
              <div class="pre-subhead-action" v-if="!onboardingData.isAdult">
                {{ nickName }}
              </div>
            </div>

            <div class="pre-divider"></div>

            <div class="pre-pad"></div>
          </div>

          <div class="pre-btns">
            <pnw-button variant="primary" size="xl" @click="gotoLastStep">
              Next
            </pnw-button>
          </div>
        </div>
        <div v-if="isLastStep">
          <div class="pre-step">
            <div class="pre-subhead">
              Complete your Paranormal Institute Profile
            </div>

            <div class="pre-copy">
              <p>
                Add a profile picture to receive your official Paranormal
                Institute identification card:
              </p>
            </div>

            <div class="pre-pad"></div>

            <error-alert v-if="error">
              Unable to upload image. Please try again.
            </error-alert>
            <div class="pre-upload">
              <pnw-button
                class="w-100 mb-4"
                variant="outline-primary"
                :disabled="!onboardingData.isAdult"
                @click="takePhoto"
              >
                {{ camera.isCameraOpen ? "Take Photo" : "Retake Photo" }}
              </pnw-button>
              <input
                type="file"
                @change="onFileChange"
                ref="file"
                hidden
                accept="image/*"
              />
              <pnw-button
                class="w-100 ml-0"
                variant="outline-primary"
                @click="$refs.file.click()"
                :disabled="!onboardingData.isAdult"
              >
                Upload Photo
              </pnw-button>
              <pnw-button
                v-if="
                  !onboardingData.inProgressing && !this.hasDefaultProfilePic
                "
                @click="skipToCompletePage(false)"
                class="w-100 ml-0 mt-4"
                variant="outline-primary"
              >
                Remove Photo
              </pnw-button>
            </div>
          </div>

          <div class="pre-btns mt-5 pt-5">
            <div
              v-if="onboardingData.inProgressing"
              class="pre-btn pre-btn-underline mr-4"
              @click="skipToCompletePage"
            >
              <div class="pre-btn--copy">Skip</div>
            </div>
            <pnw-button
              variant="primary"
              :disabled="!form.image"
              size="xl"
              @click="nextToCompletePage"
            >
              Complete
            </pnw-button>
          </div>
        </div>
      </div>

      <div class="pre-display">
        <div class="pre-id">
          <div class="pre-id--wrap">
            <div class="pre-edge">
              <img src="/assets/images/corner.png" alt="" />
              <img class="flipped" src="/assets/images/corner.png" alt="" />
            </div>

            <img
              class="pre-id--logo"
              src="/assets/images/logoStill.png"
              alt=""
            />

            <div class="pre-id--photo filtered">
              <img
                class="pre-filter--corner pre-filter--corner-topleft"
                src="/assets/images/filter-topleft.png"
                alt=""
              />
              <img
                class="pre-filter--corner pre-filter--corner-topright"
                src="/assets/images/filter-topright.png"
                alt=""
              />
              <img
                class="pre-filter--corner pre-filter--corner-bottomleft"
                src="/assets/images/filter-bottomleft.png"
                alt=""
              />
              <img
                class="pre-filter--corner pre-filter--corner-bottomright"
                src="/assets/images/filter-bottomright.png"
                alt=""
              />
              <template v-if="!camera.isCameraOpen">
                <img
                  class="pre-id--photo-src"
                  src="/assets/images/id.png"
                  alt=""
                  v-if="!form.image"
                />
                <div
                  v-if="form.image"
                  :style="{ backgroundImage: 'url(' + form.image + ')' }"
                  class="uploaded-image"
                ></div>
              </template>

              <div
                v-if="camera.isCameraOpen"
                v-show="!camera.isLoading"
                class="camera-box"
                :class="{ flash: camera.isShotPhoto }"
              >
                <div class="camera-shutter"></div>
                <video
                  v-show="!camera.isPhotoTaken"
                  ref="camera"
                  :width="camera.imgWidth"
                  :height="camera.imgHeight"
                  @playing="playing"
                  autoplay
                ></video>
                <canvas
                  v-show="camera.isPhotoTaken"
                  id="photoTaken"
                  ref="canvas"
                  :width="camera.imgWidth"
                  :height="camera.imgHeight"
                ></canvas>
              </div>
            </div>

            <div class="pre-id--role">
              <img
                class="pre-id--role-img flipped"
                src="/assets/images/corner.png"
                alt=""
              />
              <div class="pre-id--role-name">{{ jobTitle }}</div>
            </div>

            <div class="pre-subhead glowing">{{ nickName }}</div>

            <div class="pre-pad"></div>

            <div class="pre-divider"></div>

            <div class="pre-id--footer">
              <div class="pre-id--url">
                www.ghostbusters-paranormal.institute
              </div>
            </div>

            <div class="pre-edge">
              <img class="flipped" src="/assets/images/corner.png" alt="" />
              <img src="/assets/images/corner.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <completing v-if="completing" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ErrorAlert from "../../components/ErrorAlert.vue";
import routerItems from "../../constants/routerItems";
import Completing from "./Completing.vue";
import random from "random-name";
import http from "../../services/HttpService";
import PnwButton from "../../components/PnwButton.vue";
import PnwInput from "../../components/PnwInput.vue";
import generateName from "../../utils/nameGenerator";
import playSound from "../../services/AudioService";

export default {
  components: { ErrorAlert, Completing, PnwButton, PnwInput },
  name: "OnboardingComplete",
  data() {
    return {
      isLastStep: false,
      completing: false,
      jobTitle: "",
      nickName: "",
      editing: false,

      form: { name: "", image: null },
      file: null,

      camera: {
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        videoWidth: 704,
        videoHeight: 528,
        imgWidth: 352,
        imgHeight: 264,
      },
      creating: false,
      hasWebcam: false,
      error: null,
    };
  },
  methods: {
    ...mapActions({
      resetAction: "registerStore/reset",
      updateProfile: "updateProfile",
      addProfile: "addProfile",
    }),
    gotoLastStep() {
      if (this.onboardingData.isAdult) {
        this.isLastStep = true;
      } else {
        this.nextToCompletePage();
      }
    },
    async skipToCompletePage(createProfile = true) {
      this.camera.isCameraOpen = false;

      let photos = ["boy.png", "girl.png"];
      if (this.onboardingData.isAdult) {
        photos = ["female.png", "male.png"];
      }
      const photo = photos[Math.round(Math.random() * 1000) % 2];
      this.form.image = "/assets/images/profiles/" + photo;

      if (createProfile) {
        await this.createProfile(this.form.image);
        this.$router.push({ name: routerItems.PROFILE });
      }
    },
    async createProfile(fileLocation = "") {
      try {
        const userInfo = {
          isAdult: this.onboardingData.isAdult,
          ticketCode: this.onboardingData.ticketCode,
          listingId: this.onboardingData.listingId,
          ticketType: this.onboardingData.ticketType,
          nickName: this.nickName,
          jobTitle: this.jobTitle,
          photoUrl: fileLocation,
        };
        if (this.onboardingData.newProfile) {
          await this.addProfile(userInfo);
        } else {
          await this.updateProfile({
            profileId: this.currentProfile.id,
            profile: userInfo,
          });
        }
        playSound("GF-PN1-ProfileAssigned.wav");
        this.resetAction();
        this.$router.push({ name: routerItems.PROFILE });
      } catch (err) {}
    },
    nextToCompletePage() {
      fetch(this.form.image)
        .then((res) => res.blob())
        .then(async (blob) => {
          try {
            this.error = null;
            this.completing = true;

            // let fileLocation = this.form.image;
            // if (!this.form.image.includes("/assets/images/profiles/")) {
            let fileName =
              Math.random().toString(36).substring(2, 10) +
              Math.random().toString(36).substring(2, 10) +
              "-" +
              this.nickName.replace(/[^a-zA-Z0-9]/g, "") +
              "-" +
              this.onboardingData.ticketType.replace(/[^a-zA-Z0-9]/g, "") +
              ".png";

            // if (
            //   !this.hasDefaultProfilePic &&
            //   this.currentProfile &&
            //   this.currentProfile.photoUrl
            // ) {
            //   //replace current image
            //   let parts = this.currentProfile.photoUrl.split("/");
            //   fileName = parts[parts.length - 1];
            // }

            this.file = new File([blob], fileName, {
              type: "image/png",
            });
            const formData = new FormData();
            formData.append("photo", this.file);
            formData.append("title", this.nickName + "-photo.png");
            const { data: { file } = {} } = await http.post(
              "/api/file-upload",
              formData
            );
            let fileLocation = file.location;
            // }
            //TODO delete unused image

            this.createProfile(fileLocation);
          } catch (err) {
            playSound("GF-SpiritNet-Error.wav");
            this.error = err;
            this.completing = false;
          }
        });
    },
    generateNewNickName() {
      this.nickName = random.first() + " " + random.last();
    },

    async checkWebCam() {
      try {
        var md = navigator.mediaDevices;
        var results = false;

        if (!md || !md.enumerateDevices) results = false;

        const devices = await md.enumerateDevices();
        results = devices.some((device) => "videoinput" === device.kind);
        this.hasWebcam = results;
        return results;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    onFileChange(e) {
      const file = e.target.files[0];

      this.file = file;
      this.form.image = URL.createObjectURL(file);
      this.closeCameraStream();
    },
    openCamera() {
      this.camera.isCameraOpen = true;
      this.createCameraElement();
    },
    playing(event) {
      this.camera.videoWidth = event.target.videoWidth;
      this.camera.videoHeight = event.target.videoHeight;
    },
    createCameraElement() {
      this.camera.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.camera.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.camera.isLoading = false;
          this.camera.isCameraOpen = false;
          alert("Could not use webcam");
        });
    },

    stopCameraStream() {
      if (this.$refs.camera?.srcObject) {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
    },

    closeCameraStream() {
      this.camera.isShotPhoto = false;
      this.camera.isCameraOpen = false;
      this.camera.isPhotoTaken = false;
      this.camera.isShotPhoto = false;
      this.stopCameraStream();
    },
    takePhoto() {
      if (!this.camera.isCameraOpen) {
        this.openCamera();
        return;
      }
      if (!this.camera.isPhotoTaken) {
        this.camera.isShotPhoto = true;

        const FLASH_TIMEOUT = 100;

        setTimeout(() => {
          this.closeCameraStream();
        }, FLASH_TIMEOUT);
      }

      playSound("GF-PN1-CameraShutter.wav");
      this.camera.isPhotoTaken = !this.camera.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        this.camera.videoWidth,
        this.camera.videoHeight,
        0,
        0,
        this.camera.imgWidth,
        this.camera.imgHeight
      );
      this.form.image = this.$refs.canvas.toDataURL();
    },
  },
  mounted() {
    if (this.onboardingData.inProgressing) {
      //creating new profile
      this.jobTitle = generateName(
        this.onboardingData.selectedActivities.length
      );
      if (this.onboardingData.isAdult) {
        const photos = ["female.png", "male.png"];
        const photo = photos[Math.round(Math.random() * 1000) % 2];
        this.form.image = "/assets/images/profiles/" + photo;
        this.nickName =
          this.currentUser.firstName +
          " " +
          this.currentUser.lastName.charAt(0);
        this.checkWebCam();
        this.openCamera();
      } else {
        this.nickName = this.jobTitle;
        const photos = ["boy.png", "girl.png"];
        const photo = photos[Math.round(Math.random() * 1000) % 2];
        this.form.image = "/assets/images/profiles/" + photo;
      }
    } else {
      this.$router.replace({ name: routerItems.TICKET_CODE });
      //editing current profile
      // if(!this.currentProfile.isAdult){
      //   this.$router.push({ name: routerItems.PROFILE });
      // }
      // this.jobTitle = this.currentProfile.jobTitle;
      // this.nickName = this.currentProfile.nickName;
      // this.onboardingData.ticketCode = this.currentProfile.ticketCode;
      // this.onboardingData.listingId = this.currentProfile.listingId;
      // this.onboardingData.ticketType = this.currentProfile.ticketType;
      // this.onboardingData.isAdult = this.currentProfile.isAdult;
      //
      // if (this.currentProfile.photoUrl) {
      //   this.form.image = this.currentProfile.photoUrl;
      // } else {
      //   this.checkWebCam();
      //   this.openCamera();
      // }
    }
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
      currentUser: "currentUser",
      onboardingData: "registerStore/onboardingData",
      isAuthenticated: "isAuthenticated",
    }),
    prevWord() {
      const allVowels = "aeiouAEIOU";
      if (this.jobTitle && allVowels.includes(this.jobTitle.charAt(0))) {
        return "an";
      }
      return "a";
    },
    hasDefaultProfilePic() {
      return this.currentProfile.photoUrl.includes("/assets/images/profiles/");
    },
  },
};
</script>
<style lang="scss" scoped>
.pre-subhead-action--icon {
  cursor: pointer;
  width: 32px;
}
.pre-subhead-action--copy {
  font-size: 36px;
  text-shadow: 0px 0px 20px #80ff44;
  border: none;
  outline: none;
  margin-right: 16px;
}
.uploaded-image {
  width: 100%;
  min-width: 100%;
  height: 268px;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
