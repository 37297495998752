<template>
  <div class="pre-body pre-body-sm">
    <div class="pre-completing filtered">
      <img
        class="pre-filter--corner pre-filter--corner-topleft"
        src="/assets/images/filter-topleft.png"
        alt=""
      />
      <img
        class="pre-filter--corner pre-filter--corner-topright"
        src="/assets/images/filter-topright.png"
        alt=""
      />
      <img
        class="pre-filter--corner pre-filter--corner-bottomleft"
        src="/assets/images/filter-bottomleft.png"
        alt=""
      />
      <img
        class="pre-filter--corner pre-filter--corner-bottomright"
        src="/assets/images/filter-bottomright.png"
        alt=""
      />
      <img class="pre-completing--src" src="/assets/images/id.png" alt="" />
    </div>

    <div class="pre-subhead centered caps">Completing Profile...</div>
  </div>
</template>
<script>
export default {
  name: "Completing",
};
</script>
